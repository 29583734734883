import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_BENEFICIARY_FORM,
  GET_BENEFICIARY_FORM_SUCCESS,
  GET_BENEFICIARY_FORM_FAIL,
  GET_EDIT_BENEFICIARY_FORM,
  GET_EDIT_MISSING_BENEFICIARY_FORM,
  VERIFY_BENEFICIARY_FORM,
  VERIFY_BENEFICIARY_FORM_SUCCESS,
  VERIFY_BENEFICIARY_FORM_FAIL,
  SUBMIT_BENEFICIARY_FORM,
  SUBMIT_BENEFICIARY_FORM_SUCCESS,
  SUBMIT_BENEFICIARY_FORM_FAIL,
  SUBMIT_EDIT_BENEFICIARY_FORM,
  SUBMIT_EDIT_BENEFICIARY_FORM_SUCCESS,
  SUBMIT_EDIT_BENEFICIARY_FORM_FAIL,
  SUBMIT_EDIT_MISSING_BENEFICIARY_FORM,
  SUBMIT_EDIT_MISSING_BENEFICIARY_FORM_SUCCESS,
  SUBMIT_EDIT_MISSING_BENEFICIARY_FORM_FAIL,
  SUBMIT_BENEFICIARY_BANK_FORM,
  SUBMIT_BENEFICIARY_BANK_FORM_SUCCESS,
  SUBMIT_BENEFICIARY_BANK_FORM_FAIL,
} from "../Assets/Constant/BeneficiaryForm";

import { getBaseUrl, COMPLIANCE } from '../Network/baseUrl';
import http from '../Network/http';
import {REQUEST_TAC} from "../Assets/Constant/CompleteProfileForm";

function* getBeneficiaryForm() {
  try {
    const region = localStorage.getItem('region');
    const account_currency = localStorage.getItem('account-currency');
    const payout_method = localStorage.getItem("payout-method");
    const compliance_account_id = localStorage.getItem("compliance-account-id");

    const apiConfig = {
      method: "get",
      baseURL: getBaseUrl(COMPLIANCE),
      url: "/beneficiaries/create",
      params: {
        region,
        account_currency,
        payout_method,
        compliance_account_id,
      },
    };
    const res = yield call(http, apiConfig);
    const { data: { data } } = res;

    yield put({
      type: GET_BENEFICIARY_FORM_SUCCESS,
      data,
    });

    localStorage.removeItem("account-currency");
    localStorage.removeItem("payout-method");
    localStorage.removeItem("compliance-account-id");
  } catch (e) {
    yield put({
      type: GET_BENEFICIARY_FORM_FAIL,
      error: e,
    });
  }
}

function* verifyBeneficiaryInput(item) {
  const region = localStorage.getItem('region');

  try {
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/beneficiaries/verify-input',
      data: JSON.stringify({
        ...item.data,
        entity_type: item.entity_type,
        region,
      })
    };
    const res = yield call(http, apiConfig);
    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: VERIFY_BENEFICIARY_FORM_SUCCESS,
        data,
        target: item.data.__target
      });
    } else {
      yield put({
        type: VERIFY_BENEFICIARY_FORM_FAIL,
        data,
        target: item.data.__target,
        errors
      });
    }

  } catch (e) {
    console.log('error', e);
  }
}

function* submitBeneficiaryForm(item) {
  const region = localStorage.getItem('region');

  try {
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/beneficiaries',
      data: JSON.stringify({
        ...item.data,
        entity_type: item.entity_type,
        region
      })
    };
    const res = yield call(http, apiConfig);

    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: SUBMIT_BENEFICIARY_FORM_SUCCESS,
        data,
      });
    } else {
      yield put({
        type: SUBMIT_BENEFICIARY_FORM_FAIL,
        data,
        errors
      });
    }

  } catch (e) {
    console.log('error', e);
  }
}

function* submitBeneficiaryCreateBankForm(item) {
  const region = localStorage.getItem('region');
  try {
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/beneficiaries/create-bank',
      data: {
        ...item.data,
        region
      }
    };
    const res = yield call(http, apiConfig);
    const { data } = res;

    if (res.status !== 200) {
      yield put({
        type: SUBMIT_BENEFICIARY_BANK_FORM_FAIL
      });
      if (item.onRequestFailure) item.onRequestFailure(data.errors[0]);
    } else {
      if (item.onRequestSuccess) item.onRequestSuccess();
    }

  } catch (e) {
    console.log('error', e);
  }
}

function* requestTAC(item) {
  const region = localStorage.getItem('region');

  try {
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/beneficiaries/send-sms-verification',
      params: {
        region
      },
      data: {
        ...item.data,
        region: process.env.REACT_APP_REGION
      }
    };
    const res = yield call(http, apiConfig);

    if (res.status === 200) {
      console.log('success')
    } else {
      console.log('failed')
    }

  } catch (e) {
    console.log('error', e);
  }
}

function* getEditBeneficiaryForm(payload) {
  const { payoutMethod, complianceAccountId, navigate } = payload;
  const region = localStorage.getItem('region');

  try {
    const apiConfig = {
      method: 'get',
      baseURL: getBaseUrl(COMPLIANCE),
      url: `/beneficiaries/${payoutMethod}/${complianceAccountId}/edit`,
      params: {
        region
      },
    };
    const res = yield call(http, apiConfig);
    const { data: { data } } = res;

    if (res.status === 200) {
      yield put({
        type: GET_BENEFICIARY_FORM_SUCCESS,
        data,
      });
      const entityType = data.fields.find((item) => item.name === 'entity_type').value;
      if (navigate) navigate(entityType);
    }
  } catch (e) {
    yield put({
      type: GET_BENEFICIARY_FORM_FAIL,
      error: e,
    });
  }
}

function* submitEditBeneficiaryForm(payload) {
  const { filledData, payoutMethod, complianceAccountId, navigate, entity_type } = payload;
  const region = localStorage.getItem('region');
  try {
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: `/beneficiaries/${payoutMethod}/${complianceAccountId}/update`,
      params: {
        region
      },
      data: JSON.stringify({
        ...filledData,
        entity_type,
      }),
    };
    const res = yield call(http, apiConfig);

    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: SUBMIT_EDIT_BENEFICIARY_FORM_SUCCESS,
        data,
      });
      if (navigate) navigate();
    } else {
      yield put({
        type: SUBMIT_EDIT_BENEFICIARY_FORM_FAIL,
        data,
        errors,
      });
    }
  } catch (e) {
    console.log(e)
  }
}

function* getEditMissingBeneficiaryForm(payload) {
  const { payoutMethod, complianceAccountId, navigate } = payload;
  const region = localStorage.getItem('region');
  try {
    const apiConfig = {
      method: 'get',
      baseURL: getBaseUrl(COMPLIANCE),
      url: `/missing-bene-info/${payoutMethod}/${complianceAccountId}/edit`,
      params: {
        region
      },
    };
    const res = yield call(http, apiConfig);
    const { data: { data } } = res;

    if (res.status === 200 || res.status === 422) {
      yield put({
        type: GET_BENEFICIARY_FORM_SUCCESS,
        data,
      });
      const entityType = data.fields.find((item) => item.name === 'entity_type').value;
      console.log(entityType)
      if (navigate) navigate(entityType);
    }
  } catch (e) {
    yield put({
      type: GET_BENEFICIARY_FORM_FAIL,
      error: e,
    });
  }
}

function* submitEditMissingBeneficiaryForm(payload) {
  const { filledData, id, navigate, entity_type } = payload;
  const region = localStorage.getItem('region');
  try {
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: `/missing-bene-info/${id}/update`,
      params: {
        region
      },
      data: JSON.stringify({
        ...filledData,
        entity_type,
      }),
    };
    const res = yield call(http, apiConfig);

    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: SUBMIT_EDIT_BENEFICIARY_FORM_SUCCESS,
        data,
      });
      if (navigate) navigate();
    } else {
      yield put({
        type: SUBMIT_EDIT_BENEFICIARY_FORM_FAIL,
        data,
        errors,
      });
    }
  } catch (e) {
    console.log(e)
  }
}

export function* watchBeneficiaryForm() {
  yield takeLatest(GET_BENEFICIARY_FORM, getBeneficiaryForm);
  yield takeLatest(GET_EDIT_BENEFICIARY_FORM, getEditBeneficiaryForm);
  yield takeLatest(GET_EDIT_MISSING_BENEFICIARY_FORM, getEditMissingBeneficiaryForm);
  yield takeLatest(VERIFY_BENEFICIARY_FORM, verifyBeneficiaryInput);
  yield takeLatest(SUBMIT_BENEFICIARY_FORM, submitBeneficiaryForm);
  yield takeLatest(SUBMIT_EDIT_BENEFICIARY_FORM, submitEditBeneficiaryForm);
  yield takeLatest(SUBMIT_EDIT_MISSING_BENEFICIARY_FORM, submitEditMissingBeneficiaryForm);
  yield takeLatest(SUBMIT_BENEFICIARY_BANK_FORM, submitBeneficiaryCreateBankForm);
  yield takeLatest(REQUEST_TAC, requestTAC);
}
